<template>
    <Dropdown v-model="selectedEsp" :options="this.especialidades" optionLabel="nombre" filter placeholder="Especialidad" class="fixed-sizes">
        <template #value="slotProps">
            <div v-if="slotProps.value" class="flex align-items-center">                
                <div>{{ slotProps.value.nombre }}</div>
            </div>
            <span v-else>
                {{ slotProps.placeholder }}
            </span>
        </template>
        <template #option="slotProps">
            <div class="flex align-items-center">                
                <div>{{ slotProps.option.nombre }}</div>
            </div>
        </template>
    </Dropdown>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
export default ({
    data() {
        return {
            especialidades:[],
            selectedEsp:'',
        }
    },
    mounted(){
        this.cargarEspecialidades();
    },
    methods: {
        async cargarEspecialidades(){
            const api = new PwgsApi();
            const aux = await api.get('gamas-equipos');
            this.especialidades = aux.datos;
        }
    },
    watch:{
        selectedEsp(){
            this.$parent.seleccionespecialidad(this.selectedEsp)
        }
    }
})
</script>
<style>
.fixed-sizes{
    height: 38px;
}
</style>