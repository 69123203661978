<template>
    <div class="card">
        <div class="card-header bg-gray">
            <h3 class="card-title"><i class="fas fa-tv mr-2"></i>{{ $t('general.equipos') }}</h3>
            <div class="card-tools">                
                <button type="button" class="btn btn-tool"  title="Pantalla completa" data-card-widget="maximize">
                    <i class="fas fa-expand"></i>
                </button>
            </div>
        </div>
        <div class="card-body">
            <div >
                <div class="row" style="padding-left:15px">
                    <div class="row"><label for="">Agregar equipo</label></div>
                </div>
                <div class="grid-container">
                        <tipos-especialidades/>
                    
                        <equipos-especialidades :id_especialidad="id_especialidad"/>  
                    
                        <button class="btn  btn-light btn-outline-secondary btn-size" @click="agregarEquipo()">Agregar</button>
                    
                </div> 
            </div>
            
            <div class="row">
                <div class="table-responsive">
                    <table class="table table-sm table-striped">
                        <thead>
                            <th>Nombre</th>
                            <th>Marca</th>
                        </thead>
                        <tbody>
                            <tr v-for="equipo in equipos" :key="equipo.equipo.id_equipo">
                                <td width ="40%">{{equipo.equipo.nombre_equipo}}</td>
                                <td width ="40%"><equipos-marcas :equipo="equipo.equipo" :id_servicio="this.id"/></td>
                                <td width ="20%"><button class="btn btn-light btn-sm" @click="eliminarEquipo(equipo)" title="Eliminar">
                                    <i class="fas fa-trash-alt" ></i>
                                </button>
                                <button class="btn btn-light btn-sm" @click= "cambiarAdicionales(equipo.equipo.id_servicio_equipo, equipos.indexOf(equipo));" title="Añadir datos adicionales">
                                    <i class="fas fa-plus" ></i>
                                </button></td>
                                <datos-adicionales
                                    ref="ver" 
                                    :idservicio="this.id"
                                    :equipo="equipo.datos_adicionales" 
                                    :idequipo="equipo.equipo.id_servicio_equipo" 
                                />
                            </tr>
                        </tbody>
                    </table>
                </div>
                
            </div>
        </div>        
    </div>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
import tiposEspecialidades from './equipos_tipos_especialidades.vue';
import equiposEspecialidades from './equipos_equipos_especialidades.vue';
import equiposMarcas from './equipos_marcas.vue';
import datosAdicionales from './equipos_datos_adicionales.vue';
export default ({
    props: ['id'],
    components: {
        'equipos-especialidades':equiposEspecialidades,
        'tipos-especialidades':tiposEspecialidades,
        'equipos-marcas':equiposMarcas,
        'datos-adicionales': datosAdicionales,
    },
    setup() {
        return;
    },
    mounted() {
        this.cargarEquipos();
    },
    data (){
        return {
            equipos:[],
            especialidadSeleccionada:'',
            id_especialidad:'',
            id_equipo:'',
            equipo_adicionales:'',
        }
    },
    watch:{
        id(){
            this.equipos = [];
            this.cargarEquipos();
        }
    },
    methods:{ 
        cambiarAdicionales(datos, indice){
            this.equipos_adicionales = datos;
            this.$refs.ver[indice].hacerVisible();
        },
        seleccionespecialidad(especialidad){
            this.id_especialidad=especialidad.id
        },
        seleccionequipo(equipo){
            this.id_equipo=equipo
        },
        async agregarEquipo(){
            if(this.id_equipo==''){
                alert('Selecciona un equipo');
            }
            else{
                const api = new PwgsApi();
                await api.post('servicios/' + this.$props.id + '/equipos/'+ this.id_equipo.equipos_id_equipo);
                this.id_especialidad == null;
                this.cargarEquipos()
            }
        },
        async eliminarEquipo(equipo){
            const api = new PwgsApi();
            await api.delete('servicios/' + this.$props.id + '/equipos/'+ equipo.equipo.id_servicio_equipo);
            this.cargarEquipos();
        },
        async cargarEquipos(){
            const api = new PwgsApi();
            try{
                const aux = await api.get('servicios/' + this.$props.id + '/equipos');
                this.equipos = aux;
            }
            catch(error){
                this.equipos = [];
            }
        },
    },
})
</script>
<style>
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap:10px;
}

.btn-size{
    width:130px;
}
.btn-size:hover{
    background-color:rgb(163, 163, 163);
}
</style>