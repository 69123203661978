<template>
    <Dropdown v-model="selectedEquipo" :options="this.equipos" optionLabel="nombre" filter placeholder="Equipo" class="fixed-sizes w-full md:w-14rem">
        <template #value="slotProps">
            <div v-if="slotProps.value" class="flex align-items-center">                
                <div>{{ slotProps.value.nombre }}</div>
            </div>
            <span v-else>
                {{ slotProps.placeholder }}
            </span>
        </template>
        <template #option="slotProps">
            <div class="flex align-items-center">                
                <div>{{ slotProps.option.nombre }}</div>
            </div>
        </template>
    </Dropdown>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
export default ({
    props: ['id_especialidad'],
    data() {
        return {
            equipos:[],
            selectedEquipo:null,
        }
    },
    methods: {
        async cargarEquiposEspecialidad(){
            const api = new PwgsApi();
            const aux = await api.get('especialidades/' + this.id_especialidad + '/equipos');
            this.equipos = aux.datos;
        }
    },
    watch:{
       id_especialidad(){
            this.selectedEquipo = '';      
            this.cargarEquiposEspecialidad();
       },
       selectedEquipo(){
        this.$parent.seleccionequipo(this.selectedEquipo);
       }
    }
})
</script>
<style>

</style>