<template>
    <div class="row">
        <div class="col-md-12">
            <h3 v-if="servicio.compania">Compañía</h3>
            <p v-if="servicio.compania">{{servicio.compania.nombre}}</p>
            <!--  <h4 v-if="servicio.nota_importante">Nota Importante</h4>
            <p v-if="servicio.nota_importante" style="background-color:rgb(209, 208, 208)"
                v-html="servicio.nota_importante.toUpperCase()"></p>-->
            <div v-if="servicio.nota_importante" class="card bg-gradient-primary"
                style="background: #6c757d !important;">
                <div class="card-body box-profile">
                    <h4 v-if="servicio.nota_importante">Nota Importante</h4>

                    <p v-html="servicio.nota_importante"></p>
                </div>
            </div>
            <h5 v-if="servicio.danos">Descripcion siniestro</h5>
            <div v-html="servicio.danos"></div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['id','servicio','guardartoken','notaimportante'],
    components: {
    },
    data() {
        return {
            siniestro:[],
        }
    },
    methods: {
  
    },
    mounted() {
        console.log('servdatos', this.servicio);
    },
    watch: {
        id() {

        }
    }


}
</script >

