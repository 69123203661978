<template>
    <div class="card">
        <div class="card-header bg-gray cabecera-equipos">
            <h3 class="card-title"><i class="fas fa-address-card mr-2"></i>{{ $t('general.perjudicados') }}</h3>
            <div class="card-tools">
                <button type="button" class="btn btn-success btn-xs editar-perjudicado" @click="guardartoken()" id="boton_guardar_servicio" >{{ $t('general.guardar') }} </button> 
                <button type="button" class="btn btn-tool"  title="Pantalla completa" data-card-widget="maximize">
                    <i class="fas fa-expand"></i>
                </button>
                <button type="button" class="btn btn-tool ver-perjudicado"  title="Editar" v-on:click="editar">
                    <i class="fas fa-edit"></i>
                </button>
                <button type="button" class="btn btn-tool editar-perjudicado"  title="Editar" v-on:click="ver">
                    <i class="fas fa-eye"></i>
                </button>                
                <button type="button" class="btn btn-tool ampliar-perjudicado"  title="Ampliar" v-on:click="ampliar">
                    <i class="fas fa-plus"></i>
                </button>
                <button type="button" class="btn btn-tool reducir-perjudicado"  title="Reducir" v-on:click="ver">
                    <i class="fas fa-minus"></i>
                </button>
            </div>
        </div>
        <div class="card-body">
            <keep-alive>
                <component v-bind:is="component" :tokenguardarperjudicado="tokenguardarperjudicado" :perjudicado="perjudicado" :id="id" :servicio="servicio"/>            
            </keep-alive>
        </div>        
    </div>
</template>
<script>
import serviciodatosaperjudicadosimple from './perjudicados_simple.vue';
import serviciodatosaperjudicadoform from './perjudicados_form.vue';
import serviciodatosaperjudicadodetallado from './perjudicados_detallado.vue';

import $ from 'jquery'

export default ({
    props: ['id', 'servicio','perjudicado'],

    setup() {
        return;
    },mounted() {
        this.ver();
    },
    components: {
        'servicio-datos-perjudicado-simple': serviciodatosaperjudicadosimple,
        'servicio-datos-perjudicado-form': serviciodatosaperjudicadoform,
        'servicio-datos-perjudicado-detallado': serviciodatosaperjudicadodetallado,
    },
    data (){
        return {
            component: "servicio-datos-perjudicado-simple",
            tokenguardarperjudicado: false,
        }
    },
    methods: {
        editar(){
            this.component = "servicio-datos-perjudicado-form";
            $(".editar-perjudicado").show();
            $(".ver-perjudicado").hide();
            $(".cabecera-equipos").removeClass("bg-gray");
            $(".cabecera-equipos").addClass("bg-black");
        },
        ver(){
            this.component = "servicio-datos-perjudicado-simple";
            $(".editar-perjudicado").hide();
            $(".reducir-perjudicado").hide();
            $(".ampliar-perjudicado").show();
            $(".ver-perjudicado").show();
            $(".cabecera-equipos").removeClass("bg-black");
            $(".cabecera-equipos").addClass("bg-gray");
        },
        ampliar(){
            this.component = "servicio-datos-perjudicado-detallado";
            $(".ampliar-perjudicado").hide();
            $(".reducir-perjudicado").show();
            $(".cabecera-equipos").removeClass("bg-black");
            $(".cabecera-equipos").addClass("bg-gray");

        },
        guardartoken() { 
            this.tokenguardarperjudicado = !(this.tokenguardarperjudicado);
        }
    }
})
</script>