<template>

    <div class="card ">
        <div class="card-header bg-gray cabecera-perito">
            <h3 class="card-title"><i class="fas fa-user-tie mr-2"></i>{{ $t('general.perito') }}</h3>
            <div class="card-tools">
                <button type="button" class="btn btn-success btn-xs editar-perito" id="boton_guardar_servicio" @click="guardar()" >{{ $t('general.guardar') }} </button>
                
                <button type="button" class="btn btn-tool" title="Pantalla completa" data-card-widget="maximize">
                    <i class="fas fa-expand"></i>
                </button>
                <button type="button" class="btn btn-tool ver-perito" title="Ver" v-on:click="editar">
                    <i class="fas fa-edit"></i>
                </button>
                <button type="button" class="btn btn-tool editar-perito" title="Editar" v-on:click="ver">
                    <i class="fas fa-eye"></i>
                </button>                
                <button type="button" class="btn btn-tool ampliar-perito" title="Ampliar" v-on:click="ampliar">
                    <i class="fas fa-plus"></i>
                </button>
                <button type="button" class="btn btn-tool reducir-perito" title="Reducir" v-on:click="ver">
                    <i class="fas fa-minus"></i>
                </button>
            </div>
        </div>

        <div class="card-body">
           <!-- <keep-alive>-->
                <component :id="id" :servicioext="servicioext" :guardartest="guardartest" :servicio="servicio" :perito="perito" v-bind:is="component" />            
           <!-- </keep-alive>-->
        </div>
        
    </div>
</template>
<script>
import serviciodatosperitosimple from './perito_simple.vue';
import serviciodatosperitodetallado from './perito_detallado.vue';
import serviciodatosperitoform from './perito_form.vue';
import $ from 'jquery'

export default({
    props: ['id', 'servicio','perito','servicioext'],
    activated() {
        this.ver();
    },
    components: {
        'servicio-datos-perito-simple': serviciodatosperitosimple,
        'servicio-datos-perito-detallado': serviciodatosperitodetallado,
        'servicio-datos-perito-form': serviciodatosperitoform,
    },
    data (){
        return {
            component: "servicio-datos-perito-simple",
            guardartest: false,
            documentos:'',
        }
    },
    methods: {
        
      
        editar(){
            this.component = "servicio-datos-perito-form";
            $(".editar-perito").show();
            $(".ver-perito").hide();
            $(".cabecera-perito").removeClass("bg-gray");
            $(".cabecera-perito").addClass("bg-black");

        },
        ver(){
            this.component = "servicio-datos-perito-simple";
            $(".editar-perito").hide();
            $(".reducir-perito").hide();
            $(".ampliar-perito").show();
            $(".ver-perito").show();
            $(".cabecera-perito").removeClass("bg-black");
            $(".cabecera-perito").addClass("bg-gray");

        },
        ampliar(){
            this.component = "servicio-datos-perito-detallado";
            $(".ampliar-perito").hide();
            $(".reducir-perito").show();
            $(".cabecera-perito").removeClass("bg-black");
            $(".cabecera-perito").addClass("bg-gray");


        },
        guardar() {
            this.guardartest = !(this.guardartest);
        },
        watch: {
            id() { 
                this.ver();
            }
        }
    }
})
</script>